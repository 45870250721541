import React, {lazy, Suspense, useEffect} from 'react';
import {ChakraProvider, Stack} from '@chakra-ui/react';
import './App.css';
import analyticsService from './services/AnalyticsService';
import {HelmetProvider} from 'react-helmet-async';
import {reduxPersistor, reduxStore} from './store/store';
import {theme} from './chakraTheme';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

const AppProvider = lazy(() => import('./providers/AppProvider'));
const AppRoutes = lazy(() => import('./AppRoutes'));

function App() {
  useEffect(() => {
    analyticsService.initialise();
  }, []);

  return (
      <Suspense>
        <HelmetProvider>
          <ChakraProvider theme={theme}>
            <BrowserRouter>
              <Provider store={reduxStore}>
                <PersistGate loading={null} persistor={reduxPersistor}>
                  <AppProvider>
                    <Stack
                        id={'app-stack'} spacing={0}
                        height={'100%'} width={'100%'}
                        alignItems={'center'}
                    >
                      <Suspense>
                        <AppRoutes/>
                      </Suspense>
                    </Stack>

                    {/*<LoginPopUp/>*/}
                    {/*<AuthSessionEndedModal/>*/}
                  </AppProvider>
                </PersistGate>
              </Provider>
            </BrowserRouter>
          </ChakraProvider>
        </HelmetProvider>
      </Suspense>
  );
}

export default App;
