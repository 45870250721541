import { createSlice } from '@reduxjs/toolkit'
import ApiService from '../services/ApiService'
import dayjs from 'dayjs';

const staticTypes = [
    'featuredNav',
    'cardGroups',
    'cardDefaultFont',
    'cardMessageDefaultFont',
    'featuredFonts',
    'cardPriceGroups',
    'cardGroupRecipients',
    'cardGroupAges',
    'cardGroupStyles',
    'cardGroupTags',
    'cardGroupCardTypes',
]

export const staticSlice = createSlice({
    name: 'static',
    initialState: {
        cardGroups: [],
        cardGroupsLoading: false,
        isStaticDataLoading: false,
        cardDefaultFont: null,
        featuredFonts: [],
        cardPriceGroups: [],
        featuredNav: null,
        cardGroupRecipients: [],
        cardGroupAges: [],
        cardGroupStyles: [],
        cardGroupTags: [],
    },
    reducers: {
        updateCardGroupsLoading: (state, action) => {
            state.cardGroupsLoading = action.payload
        },
        updateCardGroups: (state, action) => {
            state.cardGroups = action.payload
        },
        store_static_staticDataLoading: (state, action) => {
            state.isStaticDataLoading = true
        },
        store_static_setStaticData: (state, action) => {
            state.isStaticDataLoading = false
            const payload = action.payload
            const type = payload?.type
            const data = payload?.data
            state[type] = data
        },
        store_static_setStaticUpdatedDate: (state, action) => {
            state.lastUpdated = action.payload
        },
    }
})

export const store_static_doFetchStaticData = () => async (dispatch, getState) => {
    const state = getState()
    const lastUpdated = state.staticDataSlice.lastUpdated
    // if (lastUpdated != null && dayjs().isBefore(dayjs(lastUpdated).add(1, 'days'))) {
    //     return
    // }
    dispatch(store_static_staticDataLoading())
    const promises = staticTypes.map(staticType => {
        return new ApiService().getStaticData(staticType)
            .then(response => {
                dispatch(store_static_setStaticData({
                    type: staticType,
                    data: response.result
                }))
            })
            .catch((err) => {
                dispatch(store_static_setStaticData({
                    type: staticType,
                    data: null
                }))
            })
    })

    await Promise.all(promises)
    dispatch(store_static_setStaticUpdatedDate(dayjs().toISOString()))
}

export const store_static_getCardGroups = (state) => state.staticDataSlice.cardGroups ?? [];
export const store_static_getCardGroupRecipients = (state) => state.staticDataSlice.cardGroupRecipients ?? [];
export const store_static_getCardGroupAges = (state) => state.staticDataSlice.cardGroupAges ?? [];
export const store_static_getCardGroupStyles = (state) => state.staticDataSlice.cardGroupStyles ?? [];
export const store_static_getCardGroupTags = (state) => state.staticDataSlice.cardGroupTags;
export const store_static_getCardDefaultFont = (state) => state.staticDataSlice.cardDefaultFont;
export const store_static_getCardMessageDefaultFont = (state) => state.staticDataSlice.cardMessageDefaultFont;
export const store_static_getFeaturedFonts = (state) => state.staticDataSlice.featuredFonts;
export const store_static_getFeaturedNav = (state) => state.staticDataSlice.featuredNav;
export const store_static_getCardPriceGroups = (state) => state.staticDataSlice.cardPriceGroups ?? [];

// Action creators are generated for each case reducer function
export const {
    updateCardGroups,
    store_static_staticDataLoading,
    store_static_setStaticData,
    store_static_setStaticUpdatedDate,
} = staticSlice.actions

export default staticSlice.reducer