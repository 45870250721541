import { createSlice } from '@reduxjs/toolkit'
import ApiService from '../services/ApiService'

export const notificationSlice = createSlice({
    name: 'static',
    initialState: {
        message: '',
        type: '',
        shareData: null
    },
    reducers: {
        store_notification_showError: (state, action) => {
            state.message = action.payload
            state.type = 'error'
        },
        store_notification_showSuccess: (state, action) => {
            state.message = action.payload
            state.type = 'success'
        },
        store_notification_hide: (state, action) => {
            state.message = ''
            state.type = ''
        },
        store_notification_showShareDrawer: (state, action) => {
            state.shareData = action.payload
        },
        store_notification_hideShareDrawer: (state, action) => {
            state.shareData = action.payload
        },
    }
})

export const store_notification_doOpenShareOrCopyToClipboard = (data) => async dispatch => {
    const shareData = data;
    try {
        if (window.navigator.share && window.navigator.canShare(shareData)) {
            await window.navigator.share(shareData)
        } else {
            dispatch(store_notification_showShareDrawer(shareData))
        }
    } catch (err) {
        dispatch(store_notification_showShareDrawer(shareData))
    }
}

// Action creators are generated for each case reducer function
export const {
    store_notification_showError,
    store_notification_showSuccess,
    store_notification_hide,
    store_notification_showShareDrawer,
    store_notification_hideShareDrawer,
} = notificationSlice.actions

export default notificationSlice.reducer