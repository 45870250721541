import $ from 'jquery';
import React from 'react';
import {
  store_personalise_doUpdateNewEntityFromRotation
} from '../cards/personalise/personaliseSlice';
import {reduxStore} from '../store/store'

class PeacoloAFrameUtils {
  static PEACOLO_SKY_ID = 'peacoloSkyImage';
  static PEACOLO_SKY_ASSET_ID = 'peacoloSkyImageAsset';
  static PEACOLO_USER_MESSAGES_PANEL_ID = 'peacolo-card-messages';
  static PEACOLO_USER_MESSAGES_ID_PREFIX = 'peacolo-card-message-';
  static PEACOLO_USER_MESSAGES_NEW = 'peacolo-card-message-new';
  static PEACOLO_USER_MESSAGE_CUBE_ID = 'peacolo-user-vr-cube';

  static convertObjectToHtmlProps(obj) {
    const htmlProps = {};
    for (const [key, value] of Object.entries({...obj})) {
      // console.log(`convertObjectToHtmlProps - key:${key}`, value)
      if (Array.isArray(value)) {
        htmlProps[key] = Object.values(value).join(' ');
      } else if (value === Object(value)) {
        htmlProps[key] = Object.entries(value).map(e => {
          let propKey = e[0];
          let propVal = e[1];
          // if (key === 'text') {
          //     if (propKey === 'value') {
          //         propVal = `'${propVal}'`
          //     }
          // }
          return `${propKey}: ${propVal}`;
        }).join('; ');
      } else {
        htmlProps[key] = value;
      }
    }
    return htmlProps;
  }

  static addAFrameSky({
    defaultColor = '#CBD5E0',
    viewAngle = 85,
    assetId = this.PEACOLO_SKY_ASSET_ID,
  } = {}) {
    const id= this.PEACOLO_SKY_ID
    console.log('Adding aframe peacolo sky...');
    const aFrameScene = $('a-scene');
    let skyEl = document.getElementById(id);
    if (skyEl != null) {
      skyEl.remove()
    }
    skyEl = document.createElement('a-sky');
    skyEl.setAttribute('id', id);
    skyEl.setAttribute('src', `#${assetId}`);
    // skyEl.setAttribute('src', `url(data:image/jpeg;base64,${assetId})`);
    skyEl.setAttribute('phi-start', viewAngle);
    skyEl.setAttribute('opacity', 0);
    skyEl.setAttribute('animation',
        'property: opacity; to: 1; loop: false; dur: 700');
    aFrameScene.append(skyEl);
    $('#peacoloDefaultSky').remove();
  }

  static updateAFrameSky(src, onloaded = () => {}) {
    const aFrameScene = $('a-scene');
    const id= this.PEACOLO_SKY_ID
    console.log('Updating aframe peacolo sky...');
    let skyEl = document.getElementById(id);
    if (skyEl != null) {
      skyEl.remove()
    }
    skyEl = document.createElement('a-sky');
    skyEl.setAttribute('id', id);
    skyEl.setAttribute('src', src);
    skyEl.setAttribute('phi-start', 85);
    skyEl.setAttribute('timeout', 10000);
    skyEl.addEventListener('materialtextureloaded', onloaded);

    aFrameScene.append(skyEl);
  }

  static addAFrameSkyAsset(imageUrl, onComplete = null) {
    if (imageUrl) {
      let imgElement = $(`#${this.PEACOLO_SKY_ASSET_ID}`);
      if (imgElement.length) {
        imgElement.remove();
      }
      imgElement = `<img id="${this.PEACOLO_SKY_ASSET_ID}" src="${imageUrl}" timeout="20000" crossOrigin="anonymous"/>`;
      $('#aframe-assets').append(imgElement);
      if (onComplete) {
        setTimeout(() => {
          onComplete();
        }, 300);
      }
    }
  }

  static addAFrameSkyAssetCustom(imageUrl, suffix, onComplete = null, timeout=300) {
    const id = `${this.PEACOLO_SKY_ASSET_ID}-${suffix}`
    if (imageUrl) {
      let imgElement = $(`#${id}`);
      if (imgElement.length) {
        imgElement.remove();
      }
      imgElement = `<img id="${id}" src="${imageUrl}" timeout="1000" crossOrigin="anonymous"/>`;
      $('#aframe-assets').append(imgElement);
      if (onComplete) {
        setTimeout(() => {
          onComplete();
        }, timeout);
      }
    }
  }

  static initAFrameSkyAssetListener() {
    setInterval(() => {
      let imgElement = $(`#${this.PEACOLO_SKY_ASSET_ID}`);

    }, 200)
  }

  static addCardMessagesAndGifts(messagesAndGifts = []) {
    console.log('addCardMessagesAndGifts', messagesAndGifts);
    this.addUserVrCube();
    const aFrameScene = $('a-scene');
    let peacoloCardMesagesEl = $(`#${this.PEACOLO_USER_MESSAGES_PANEL_ID}`);
    console.log('addCardMessagesAndGifts peacoloCardMesagesEl',
        peacoloCardMesagesEl);
    if (!peacoloCardMesagesEl.length) {
      console.log('addCardMessagesAndGifts - adding peacoloCardMesagesEl');
      peacoloCardMesagesEl = $(
          `<a-entity id="${this.PEACOLO_USER_MESSAGES_PANEL_ID}"></a-entity>`);
      aFrameScene.append(peacoloCardMesagesEl);
    }

    peacoloCardMesagesEl.empty();
    let yMultiplier = 0;
    for (let i = 0; i < messagesAndGifts.length; i++) {
      const {giftId, giftSenderName, giftMessage} = messagesAndGifts[i];
      const messageEl = document.createElement('a-entity');
      messageEl.setAttribute('id',
          `${this.PEACOLO_USER_MESSAGES_ID_PREFIX + giftId}`);
      messageEl.setAttribute('peacolo-card-message',
          `message:${giftMessage}; name:${giftSenderName};`);
      // messageEl.setAttribute('animation', `property: rotation; to: 0 360 0; loop: true; dur: 3000`)
      let posX = 0;
      let posY = 1.7 + yMultiplier + (yMultiplier * 0.1);
      let posZ = -1.5;
      let rotX = 0;
      let rotY = 0;
      let rotZ = 0;
      const index = i + 1;
      const modRemainder = index % 4;
      if (modRemainder === 2) {
        // RIGHT
        posX = 1.5;
        posZ = 0;
        rotY = -90;
      } else if (modRemainder === 3) {
        // LEFT
        posX = -1.5;
        posZ = 0;
        rotY = 90;
      } else if (modRemainder === 0) {
        // BACK
        posZ = 1.5;
        rotY = 180;
        yMultiplier += 1;
      }

      if (yMultiplier > 1) {
        yMultiplier = -1;
      }
      messageEl.setAttribute('position', `${posX} ${posY} ${posZ}`);
      messageEl.setAttribute('rotation', `${rotX} ${rotY} ${rotZ}`);
      // FRONT - [0 1.7 -2] [0 0 0]   - 1
      // RIGHT - [2 1.7 0] [0 -90 0]  - 2
      // LEFT - [-2 1.7 0] [0 90 0]   - 3
      // BACK - [0 1.7 2] [0 180 0]   - 4
      peacoloCardMesagesEl.append(messageEl);

      const sky = document.getElementById(this.PEACOLO_SKY_ID);
      //
      // var material = this.material = new THREE.MeshPhysicalMaterial({
      //   roughness: 0.6,
      //   transmission: 1,
      //   color: new THREE.Color('white')
      // });
      // sky.setObject3D('mask', material)
    }
  }

  static removeCardMessagesAndGifts() {
    this._removeEl(this.PEACOLO_USER_MESSAGES_PANEL_ID);
    this._removeEl(this.PEACOLO_USER_MESSAGE_CUBE_ID);
  }

  static doesCardMessagesAndGiftsExist() {
    let peacoloCardMesagesEl = $(`#${this.PEACOLO_USER_MESSAGES_PANEL_ID}`);
    return peacoloCardMesagesEl.length;
  }

  static addEditableCardMessagesAndGifts(onMessageClick, onNameClick) {
    let messageEl = document.getElementById(
        `${this.PEACOLO_USER_MESSAGES_NEW}`);
    if (!messageEl) {
      messageEl = document.createElement('a-entity');
      messageEl.setAttribute('id', this.PEACOLO_USER_MESSAGES_NEW);
      messageEl.classList.add('raycastable');
      // id="${this.PEACOLO_USER_MESSAGES_NEW}" raycastable
      $('#camera').append(messageEl);
      messageEl.setAttribute('position', '0 0 -1.5');
      messageEl.setAttribute('rotation', '0 0 0');
    }
    messageEl.setAttribute('peacolo-card-message', {
      message: 'Enter a message',
      name: 'Enter Name',
      onMessageClick: onMessageClick,
      onNameClick: onNameClick,
    });
  }

  static updateEditableCardMessage(
      name = 'Enter name', message = 'Enter a message') {
    let messageEl = document.getElementById(this.PEACOLO_USER_MESSAGES_NEW);
    messageEl.setAttribute('peacolo-card-message',
        {message: message, name: name});
  }

  static removeEditableCardMessagesAndGifts() {
    let messageEl = $(`#${this.PEACOLO_USER_MESSAGES_NEW}`);
    if (messageEl.length) {
      messageEl.remove();
    }
  }

  static isEditableCardMessagesAndGiftsOpen() {
    return $(`#${this.PEACOLO_USER_MESSAGES_NEW}`).length;
  }

  static addCardMessagesAndGiftSingleCard(message = {}, onClick) {
    let peacoloCardMesagesEl = $('#peacolo-card-message-single');
    if (!peacoloCardMesagesEl.length) {
      console.log('addCardMessagesAndGiftSingleCard');
      peacoloCardMesagesEl = $(
          '<a-entity id="peacolo-card-message-single"></a-entity>');
      $('a-scene').append(peacoloCardMesagesEl);
      peacoloCardMesagesEl.bind('click', onClick);
    }
    const {giftId, giftSenderName, giftMessage} = message;
    peacoloCardMesagesEl.attr('peacolo-card-message',
        `message:${giftMessage}; name:${giftSenderName};`);
    peacoloCardMesagesEl.attr('position', `0 1.7 -2`);
  }

  static updateCardMessagesAndGiftSingleCard(message = {}) {
    let peacoloCardMesagesEl = $('#peacolo-card-message-single');
    if (!peacoloCardMesagesEl.length) {
      return;
    }
    peacoloCardMesagesEl.empty();
    const {giftId, giftSenderName, giftMessage} = message;
    peacoloCardMesagesEl.attr('peacolo-card-message',
        `message:${giftMessage}; name:${giftSenderName};`);
    peacoloCardMesagesEl.attr('position', `0 1.7 -2`);
  }

  static addUserVrCube() {
    const aFrameScene = $('a-scene');
    let peacoloVrCubeEntity = $(`#${this.PEACOLO_USER_MESSAGE_CUBE_ID}`);
    if (!peacoloVrCubeEntity.length) {
      peacoloVrCubeEntity = $(
          `<a-entity id="${this.PEACOLO_USER_MESSAGE_CUBE_ID}"></a-entity>`);
      aFrameScene.append(peacoloVrCubeEntity);
    }
    peacoloVrCubeEntity.attr('position', `0 1.7 0`);
    peacoloVrCubeEntity.attr('scale', `10 10 10`);
    peacoloVrCubeEntity.attr('material',
        `color: white; side: back; opacity: 0.6; vertexColorsEnabled: true;`);
    peacoloVrCubeEntity.attr('geometry', `shape: box;`);
  }

  static updateNewEntityRotation() {
    const camera = document.querySelector('a-camera');
    const rotation = camera.getAttribute('rotation');
    reduxStore?.dispatch(store_personalise_doUpdateNewEntityFromRotation({...rotation}));
  }

  static requestDevicePermissions(onComplete) {
    if (typeof DeviceMotionEvent.requestPermission === 'function') {
      DeviceMotionEvent.requestPermission()
          .then(permissionState => {
            if (permissionState === 'granted') {
              window.addEventListener('devicemotion', () => {});
            }
          })
          .catch(console.error);
    } else {
      // handle regular non iOS 13+ devices
      onComplete()
    }
    onComplete()
  }

  static removeUserVrCube() {
    this._removeEl(this.PEACOLO_USER_MESSAGE_CUBE_ID);
  }

  static _removeEl(elementId) {
    let element = $(`#${elementId}`);
    element.empty();
    element.remove();
  }

  static addEntitiesToDemoPositions() {

    // <a-entity
    //     id={'peacolo-card-message-1'}
    //     peacolo-card-message={`message: FRONT; name: Bob;`}
    //     position="0 1.7 -2"
    // >
    // </a-entity>
    // <a-entity
    //     id={'peacolo-card-message-2'}
    //     peacolo-card-message={`message: RIGHT; name: Bob;`}
    //     position="2 1.7 0"
    //     rotation="0 -90 0"
    // >
    // </a-entity>
    // <a-entity
    //     id={'peacolo-card-message-2'}
    //     peacolo-card-message={`message: LEFT; name: Bob;`}
    //     position="-2 1.7 0"
    //     rotation="0 90 0"
    // >
    // </a-entity>
    //
    // <a-entity
    //     id={'peacolo-card-message-2'}
    //     peacolo-card-message={`message: BACK; name: Bob;`}
    //     position="0 1.7 2"
    //     rotation="0 180 0"
    // >
    // </a-entity>

  }

}

export default PeacoloAFrameUtils;