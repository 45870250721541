
class PeacoloAFrameCustomEntityObject {
  id: string
  type: string
  text_color: string
  text_padding: string
  text_fontSize: string
  text_fontWeight: number
  text_font: string
  text_textAlign: string
  text_wrap: number
  text_width: number
  text_backgroundColor: string
  text_backgroundOpacity: number
  text_backgroundShader: string
  text_value: string
  entity_positionZ: number
  entity_position: Array<number>
  entity_rotation: Array<number>

  constructor (
      {
        id=null,
        type=null,
        text_color=null,
        text_padding=null,
        text_fontSize=null,
        text_fontWeight=null,
        text_font=null,
        text_textAlign=null,
        text_wrap=null,
        text_width=null,
        text_backgroundColor=null,
        text_value=null,
        entity_positionZ=null,
        entity_position=null,
        entity_rotation=null,
      }
  ) {
    this.id=id
    this.type=type
    this.text_color=text_color
    this.text_padding=text_padding
    this.text_fontSize=text_fontSize
    this.text_fontWeight=text_fontWeight
    this.text_font=text_font
    this.text_textAlign=text_textAlign
    this.text_wrap=text_wrap
    this.text_width=text_width
    this.text_backgroundColor=text_backgroundColor
    this.text_value=text_value
    this.entity_positionZ=entity_positionZ
    this.entity_position=entity_position ? Array.from(entity_position) : []
    this.entity_rotation=entity_rotation ? Array.from(entity_rotation) : []
  }
}


export default PeacoloAFrameCustomEntityObject