import { createSlice } from '@reduxjs/toolkit'

export const cardVrViewSlice = createSlice({
  name: 'counter',
  initialState: {
    cardId: null,
    card: null,
    readOnly: false,
    customEntities: null,
  },
  reducers: {
    store_showCardInVrView: (state, action) => {
      state.cardId = action?.payload?.cardId
      state.card = action?.payload?.card
      state.readOnly = action?.payload?.readOnly || false
      state.customEntities = action?.payload?.customEntities ?? null
    },
    store_hideCardInVrView: (state, action) => {
      state.cardId = null
      state.card = null
      state.readOnly = false
      state.customEntities = null
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  store_showCardInVrView,
  store_hideCardInVrView,
} = cardVrViewSlice.actions

export default cardVrViewSlice.reducer