import {createSlice} from '@reduxjs/toolkit';
import ApiService from '../services/ApiService';



export const ordersSlice = createSlice({
  name: 'counter',
  initialState: {
    cardOrder: null,
    paymentClientSecret: null,
  },
  reducers: {
    store_order_checkoutOrder: (state, action) => {
      state.cardOrder = action.payload
    },
    store_cancelOrder: (state, action) => {
      state.cardOrder = null
      state.paymentClientSecret = null
    },
  },
})

export const store_order_getOrder = (state) => state.cardOrder

// Action creators are generated for each case reducer function
export const {
  store_order_checkoutOrder,
  store_cancelOrder,
} = ordersSlice.actions

export default ordersSlice.reducer