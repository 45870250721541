import { createSlice } from '@reduxjs/toolkit'

export const featuresSlice = createSlice({
  name: 'counter',
  initialState: {
    featureCardThumbnailVersion: 2,
    featureAllowMaintenanceByPass: false,
  },
  reducers: {
    store_updateFeatureCardThumbnailVersion: (state, action) => {
      const currentFeatureCardThumbnailVersion = state.featureCardThumbnailVersion
      state.featureCardThumbnailVersion = action.payload ?? currentFeatureCardThumbnailVersion === 1 ? 2 : 1
    },
    store_updateFeatureAllowMaintenanceByPass: (state, action) => {
      state.featureAllowMaintenanceByPass = action.payload === 'PEACOLO2024'
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  store_updateFeatureCardThumbnailVersion,
  store_updateFeatureAllowMaintenanceByPass,
} = featuresSlice.actions

export const store_feature_allowMaintenanceByPass = (state) => state.featuresSlice.featureAllowMaintenanceByPass

export default featuresSlice.reducer