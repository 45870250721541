import {createSlice} from '@reduxjs/toolkit';
import {store_notification_showError} from '../../store/notificationSlice';
import ApiService from '../../services/ApiService';

const apiService = new ApiService();

export const orderViewSlice = createSlice({
  name: 'orderViewSlice',
  initialState: {
    order: null,
    guestUserCode: null,
    isLoading: false,
    isSubmitMessageLoading: false,
    isCardMessagesVisible: false,
    // gifts/messages for order pagination
    orderMessagePages: [],
    orderMessageViewPageIndex: 0,
    // Modals
    isModalAddNewMessagesVisible: false,
    cardMessageToEdit: null, // if set this will auto-populate the message modal
    isSendCardModalOpen: false,
    isShareCardModalOpen: false,
    isVerifyCardOrderOwnerModalOpen: false,
  },
  reducers: {
    store_orderView_setOrder: (state, action) => {
      const newOrder = action?.payload

      if (newOrder?.orderId != state?.order?.orderId) {
        state.guestUserCode = null
      }

      state.order = action?.payload

      // set pages for card messages
      const chunkSize = 12; // 3 cards per size (4 sides)
      const messagePages = []; // 3 cards per size (4 sides)
      for (let i = 0; i < state.order?.gifts.length; i += chunkSize) {
        const chunk = state.order.gifts.slice(i, i + chunkSize);
        messagePages.push(chunk);
      }
      state.orderMessagePages = messagePages
      state.orderMessageViewPageIndex = 0

    },
    store_orderView_setGuestUserCode: (state, action) => {
      state.guestUserCode = action?.payload
    },
    store_orderView_setIsLoading: (state, action) => {
      state.isLoading = action?.payload ?? false
    },
    store_orderView_setIsCardMessagesVisible: (state, action) => {
      state.isCardMessagesVisible = action?.payload ?? false
      state.orderMessageViewPageIndex = 0
    },
    // gifts/messages for order pagination
    store_orderView_setOrderMessagePages: (state, action) => {
      state.orderMessagePages = action?.payload ?? []
    },
    store_orderView_setOrderMessagePageIndex: (state, action) => {
      state.orderMessageViewPageIndex = action?.payload
    },
    // Modals
    store_orderView_setIsSubmitMessageLoading: (state, action) => {
      state.isSubmitMessageLoading = action?.payload ?? false
    },
    store_orderView_setIsModalAddNewMessagesVisible: (state, action) => {
      state.isModalAddNewMessagesVisible = action.payload
    },
    store_orderView_setIsSendCardModalOpen: (state, action) => {
      state.isSendCardModalOpen = action.payload
    },
    store_orderView_setIsShareCardModalOpen: (state, action) => {
      state.isShareCardModalOpen = action.payload
    },
    store_orderView_setIsVerifyCardOrderOwnerModalOpen: (state, action) => {
      state.isVerifyCardOrderOwnerModalOpen = action.payload
    },
    store_orderView_resetDataAndHideAllModals: (state, action) => {
      state.isVerifyCardOrderOwnerModalOpen = false
      state.isShareCardModalOpen = false
      state.isSendCardModalOpen = false
      state.isModalAddNewMessagesVisible = false
      state.isSubmitMessageLoading = false
      state.orderMessagePages = []
    },
  },
})

export const store_orderView_doFetchOrderForView = (orderId) => async dispatch =>  {
  dispatch(store_orderView_setIsLoading(true));
  dispatch(store_orderView_resetDataAndHideAllModals(true));
  apiService.getPublicOrderById(orderId)
      .then(r => {
        const order = r.result
        dispatch(store_orderView_setOrder(order))
        dispatch(store_orderView_setIsCardMessagesVisible(false))
      })
      .catch(e => {
        dispatch(store_notification_showError(e.message))
        dispatch(store_orderView_setOrder(null))
      })
      .finally(() => dispatch(store_orderView_setIsLoading(false)));
}

export const store_orderView_doVerifyCardOrderGuest = (orderId, orderCode) => async dispatch =>  {
  dispatch(store_orderView_setIsLoading(true));
  await apiService.verifyCardOrderGuest(orderId, orderCode)
      .then((response) => {
        console.log('verifyCardOrderGuest complete')
        dispatch(store_orderView_setGuestUserCode(orderCode))
        dispatch(store_orderView_setIsVerifyCardOrderOwnerModalOpen(false))
      })
      .catch((err) => {
        dispatch(store_notification_showError(err.message))
        dispatch(store_orderView_setIsVerifyCardOrderOwnerModalOpen(false))
      })
      .finally(() => dispatch(store_orderView_setIsLoading(false)));
}

export const store_orderView_doSubmitCardMessage = (values) => async (dispatch, getState) =>  {
  dispatch(store_orderView_setIsSubmitMessageLoading(true));
  const {name, message, email} = values;
  const state = getState()
  const orderId = state.orderViewSlice?.order?.orderId;
  apiService.postPublicOrderMessage(orderId, name, message, email)
      .then(response => {
        dispatch(store_orderView_setOrder(response.result));
        dispatch(store_orderView_setIsModalAddNewMessagesVisible(false));
        dispatch(store_orderView_setIsCardMessagesVisible(true));
      })
      .catch(err => dispatch(store_notification_showError(err.message)))
      .finally(_ => dispatch(store_orderView_setIsSubmitMessageLoading(false)));
}

export const store_orderView_doEditCardMessage = (messageId) => async (dispatch, getState) =>  {
  dispatch(store_orderView_setIsModalAddNewMessagesVisible(true));
  const {messageId, name, message, email} = values;
  const state = getState()
  const orderGiftsAndMessages = state.orderViewSlice?.order?.gifts;
  const foundMessage = orderGiftsAndMessages.find(msg => msg.giftId = messageId)

}


export const store_orderView_getOrder = (state) => state.orderViewSlice?.order ?? null
export const store_orderView_getGuestUserCode = (state) => state.orderViewSlice?.guestUserCode ?? null
export const store_orderView_getOrderCheckoutSessionId = (state) => state.orderViewSlice?.orderCheckoutSessionId ?? null
export const store_orderView_isLoading = (state) => state.orderViewSlice?.isLoading ?? false
export const store_orderView_isCardMessagesVisible = (state) => state.orderViewSlice?.isCardMessagesVisible ?? false
export const store_orderView_isCardOwnerOrGuestVerified = (state) => (state.authSlice.currentUser?.id === state.orderViewSlice?.order?.userId) || state.orderViewSlice?.guestUserCode != null || false
export const store_orderView_isSubmitMessageLoading = (state) => state.orderViewSlice?.isSubmitMessageLoading ?? false
export const store_orderView_getOrderMessagePages = (state) => state.orderViewSlice?.orderMessagePages ?? []
export const store_orderView_getOrderMessageViewPageIndex = (state) => state.orderViewSlice?.orderMessageViewPageIndex
export const store_orderView_isModalAddNewMessagesVisible = (state) => state.orderViewSlice?.isModalAddNewMessagesVisible || false
export const store_orderView_isSendCardModalOpen = (state) => state.orderViewSlice?.isSendCardModalOpen || false
export const store_orderView_isShareCardModalOpen = (state) => state.orderViewSlice?.isShareCardModalOpen || false
export const store_orderView_isVerifyCardOrderOwnerModalOpen = (state) => state.orderViewSlice?.isVerifyCardOrderOwnerModalOpen || false

export const {
  store_orderView_setOrder,
  store_orderView_setGuestUserCode,
  store_orderView_setIsLoading,
  store_orderView_setIsCardMessagesVisible,
  // pagination
  store_orderView_setOrderMessagePages,
  store_orderView_setOrderMessagePageIndex,
  // modals
  store_orderView_setIsSubmitMessageLoading,
  store_orderView_setIsModalAddNewMessagesVisible,
  store_orderView_setIsSendCardModalOpen,
  store_orderView_setIsShareCardModalOpen,
  store_orderView_setIsVerifyCardOrderOwnerModalOpen,
  store_orderView_resetDataAndHideAllModals,
} = orderViewSlice.actions

export default orderViewSlice.reducer