import {createSlice} from '@reduxjs/toolkit';
import analyticsService from '../services/AnalyticsService';

export const cookiesSlice = createSlice({
  name: 'counter',
  initialState: {
    hasCookieDrawerBeenShown: false,
    performanceCookies: {
      label: "Performance Cookies",
      enabled: false,
    },
    functionalCookies: {
      label: "Functional Cookies",
      enabled: false,
    },
    targetingCookies: {
      label: "Targeting Cookies",
      enabled: false,
    },
  },
  reducers: {
    store_cookies_toggle: (state, action) => {
      state.cardOrder = action.payload
    },
    store_cookies_acceptAll: (state, action) => {
      state.performanceCookies.enabled = true
      state.functionalCookies.enabled = true
      state.targetingCookies.enabled = true
      state.hasCookieDrawerBeenShown= true
    },
    store_cookies_accept: (state, action) => {
      state.performanceCookies.enabled = action.payload.performanceCookies ?? false
      state.functionalCookies.enabled = action.payload.functionalCookies ?? false
      state.targetingCookies.enabled = action.payload.targetingCookies ?? false
      state.hasCookieDrawerBeenShown= true

      if (state.targetingCookies.enabled) {
        analyticsService.enableTracking()
      } else {
        analyticsService.sendEvent(analyticsService.events.trackingRejected)
        analyticsService.disableTracking()
      }
    },
  },
})

export const store_cookies_hasCookiesAlreadyShown = (state) => state.cookiesSlice.hasCookieDrawerBeenShown

// Action creators are generated for each case reducer function
export const {
  store_cookies_toggle,
  store_cookies_acceptAll,
  store_cookies_accept,
} = cookiesSlice.actions

export default cookiesSlice.reducer