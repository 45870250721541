import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <StrictMode>
      <App/>
    </StrictMode>
);


//giffy api token: 2VmiURjfP8tjKydnnsg0GSCnjI710O3B

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
