import axios from 'axios';
import isEmpty from 'lodash/isEmpty'
import appConfig from '../config/appConfig';
import {Card, CardGroup, User} from '../models';
import {reduxStore} from '../store/store'
import dayjs from 'dayjs';

class ApiResponse<T> {
  message: string;
  error: string;
  result: T;
}

class CustomApiError extends Error {
  statusCode: number

  constructor(message, statusCode) {
    super(message);
    this.statusCode = statusCode
  }
}

export default class ApiService {

  constructor(token: string, onUnauthorisedHandler: () => null) {
    this.onUnauthorisedHandler = onUnauthorisedHandler;
    // this.axiosInstance = props.axiosInstance
    this.axiosInstance = axios.create({
      baseURL: appConfig.SERVICE_API_URL,
      timeout: 60000,
      withCredentials: true
    });
    this.axiosInstance.defaults.timeout = 60000;
    // this.axiosInstance.defaults.headers['Authorization'] = `Bearer ${token || 'default'}`;
  }

  handleApiError(err, other) {
    const response = err.response;
    if (response == null) {
      throw new Error(DEFAULT_NO_RESPONSE_ERROR)
    }
    const status = response.status;
    if (status === 401) {
      const peacoloAuthLastRefreshed = window.localStorage.getItem('peacoloAuthLastRefreshed')
      if (dayjs().isAfter(dayjs(peacoloAuthLastRefreshed).add(3, 'hours'))) {
        window.localStorage.setItem('peacoloAuthLastRefreshed', new Date().toISOString())
        window.location.reload();  // Refresh the window
      } else {
        // maybe we can throw error here: TODO
      }
      // return because we don't want to throw an error for 401
      // as the backend should refresh
      return
    }
    const data = response.data;
    throw new CustomApiError(data?.error, status)
  }

  handleApiResponse(response) {
    return response?.data
  }

  isDev() {
    return appConfig.SERVICE_API_URL.includes("/dev")
  }

  isProd() {
    return appConfig.SERVICE_API_URL.includes("prod")
  }

  //========================================
  //========================================
  // Auth
  //========================================
  //========================================

  async getCurrentUser(): Promise<ApiResponse<{user: User, token: string}>> {
    return await this.axiosInstance.get('/api/users/current-user')
        .then((r) => this.handleApiResponse(r))
        .catch((err) => this.handleApiError(err))
  }

  async refreshToken(forceRefresh = false): Promise<ApiResponse<{user: User, token: string}>> {
    const params = {}
    if (forceRefresh) {
      params['forceRefresh'] = true
    }
    return await this.axiosInstance.get('/api/auth/refresh-token', {
      params: params
    })
        .then((r) => this.handleApiResponse(r))
  }

  async getNewUserToken(): Promise<ApiResponse<{user: User}>> {
    return await this.axiosInstance.get('/api/auth/get-token')
        .then((r) => this.handleApiResponse(r))
  }

  async postSignIn(username: string, password: string, confirmPassword: string = null, isCreatorSignUp = false): Promise<ApiResponse<User>> {
    return await this.axiosInstance.post('/api/auth/sign-in', {
      username: username,
      password: password,
      confirmPassword: confirmPassword,
      isCreatorSignUp: isCreatorSignUp,
    })
        .then((r) => this.handleApiResponse(r))
        .catch((err) => this.handleApiError(err))
  }

  async postSignOut(): Promise<ApiResponse<{user: User}>> {
    return await this.axiosInstance.post('/api/auth/sign-out')
        .then((r) => this.handleApiResponse(r))
        .catch((err) => this.handleApiError(err))
  }

  async getProfileForUsername(username: string): Promise<ApiResponse<any>> {
    return await this.axiosInstance.get(`/api/users/profile`, {
      params: {
        username: username
      }
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  async postUserCreatorData(data: any): Promise<ApiResponse<any>> {
    return await this.axiosInstance.post(`/api/users/update`, data, {
      params: {
        updateType: "creator"
      }
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  async postStripeOnboarding(): Promise<ApiResponse<{user: User, token: string}>> {
    return await this.axiosInstance.post('/api/users/stripe-onboarding')
        .then((r) => this.handleApiResponse(r))
        .catch((err) => this.handleApiError(err))
  }

  postRequestEmailVerificationCode() {
    return this.axiosInstance.post('/api/users/request-email-verification')
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  postConfirmEmailVerificationCode(verifyCode) {
    return this.axiosInstance.post('/api/users/confirm-email-verification', {
      verifyCode: verifyCode
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  postPasswordResetRequestCode(email: string) {
    return this.axiosInstance.post('/api/password-reset/request', {
      email: email
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  postPasswordResetVerifyCode(email, verifyCode) {
    return this.axiosInstance.post('/api/password-reset/verify', {
      email: email,
      verifyCode: verifyCode
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  postPasswordResetChangePassword(email, verifyCode, newPassword) {
    return this.axiosInstance.post('/api/password-reset/confirm', {
      email: email,
      verifyCode: verifyCode,
      newPassword: newPassword,
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  //========================================
  //========================================
  // Cards
  //========================================
  //========================================

  getCardsByUsername(username: string, page: any) : Promise<ApiResponse<{cards: Array<Card>, page: string}>> {
    return this.axiosInstance.get(`/api/cards`, {
      params: {
        username: username,
        page: page,
      }
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }


  getCardsByFilter(data = {occasion: null, page: null}, queryParams) : Promise<ApiResponse<{cards: Array<Card>, page: string}>> {
    return this.axiosInstance.get(`/api/cards`, {
      params: {
        occasion: data.occasion,
        page: data.page != null ? JSON.stringify(data.page) : null,
        ...queryParams
      }
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  getCardsBySearch(query) {
    return this.axiosInstance.get(`/api/cards/search`, {
      params: {
        query: query,
      }
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  getCardCategories() : Promise<ApiResponse<Array<CardGroup>>> {
    return this.axiosInstance.get(`/api/cards/get-groups`, )
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  getStaticData(type) : Promise<ApiResponse<any>> {
    return this.axiosInstance.get(`/api/cards/static-data`, {
      params: {
        type: type
      }
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  getCardEditData() : Promise<ApiResponse<{
    cardGroups: Array<CardGroup>,
    cardPrices: Array<Number>,
  }>> {
    return this.axiosInstance.get(`/api/cards/editCardsPage`, {
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  getCardsForLanding() : Promise<ApiResponse<{cards: Array<Card>}>> {
    return this.axiosInstance.get(`/api/cards/landing`, )
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  getCardsForShop() : Promise<ApiResponse<{groups: Array<CardGroup>, cardsByGroup:{groupId: string, groupName: string, cards: Array<Card>}}>> {
    return this.axiosInstance.get(`/api/cards/shop`, )
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }


  updateCard(card: Card) : Promise<ApiResponse<Card>> {
    return this.axiosInstance.post(`/api/cards/${card?.id}`, card)
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  archiveCard(cardId: string) : Promise<ApiResponse<Card>> {
    return this.axiosInstance.post(`/api/cards/${cardId}`, {
      actionArchiveCard: true
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  deleteCard(cardId: string) : Promise<ApiResponse<any>> {
    return this.axiosInstance.delete(`/api/cards/${cardId}`)
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  getCardById(id: string) : Promise<ApiResponse<Card>> {
    return this.axiosInstance.get(`/api/cards/${id}`)
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  getCardPurchaseValidity(id: string) : Promise<ApiResponse<Card>> {
    return this.axiosInstance.get(`/api/cards/${id}`, {
      params: {
        getCardAction: 'purchase-valid'
      }
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  getCardsForCreatorProfile(page) : Promise<ApiResponse<{cards: Array<Card>, page: any}>> {
    return this.axiosInstance.get('/api/cards/creator-profile', {
      params: {
        page: page,
      }
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  getCardAuditByCardId(cardId: string) : Promise<ApiResponse<Array<any>>> {
    return this.axiosInstance.get(`/api/cards/${cardId}`, {
      params: {
        getCardAction: 'audit'
      }
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  postCreateUploadUrl(type, cardId, filename) {
    return this.axiosInstance.post('/api/cards/upload-url', {
        type: type,
        cardId: cardId,
        filename: filename,
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  //========================================
  //========================================
  // Orders
  //========================================
  //========================================

  submitOrder(cardId: string, options: any, entities: any) : Promise<ApiResponse<any>>  {
    return this.axiosInstance.post('/api/orders/', {
      cardId: cardId,
      entities: entities,
      options: options
    })
    .then(this.handleApiResponse)
    .catch((err) => this.handleApiError(err))
  }

  generateSendCardUrl(orderId: string, orderSendCode: string) : Promise<ApiResponse<any>>  {
    return this.axiosInstance.post(`/api/orders/${orderId}`, {
      action: 'generate-send-card-url',
      orderId: orderId,
      orderSendCode: orderSendCode,
    })
    .then(this.handleApiResponse)
    .catch((err) => this.handleApiError(err))
  }

  verifyCardOrderGuest(orderId: string, orderSendCode: string) : Promise<ApiResponse<any>>  {
    return this.axiosInstance.post(`/api/orders/${orderId}`, {
      action: 'verify-guest',
      orderId: orderId,
      orderSendCode: orderSendCode,
    })
    .then(this.handleApiResponse)
    .catch((err) => this.handleApiError(err))
  }

  postOrderCustom(orderId: string, action: string, data: any = {}) : Promise<ApiResponse<any>>  {
    return this.axiosInstance.post(`/api/orders/${orderId}`, {
      action: action,
      ...data
    })
    .then(this.handleApiResponse)
    .catch((err) => this.handleApiError(err))
  }

  getOrderById(orderId: string) : Promise<ApiResponse<any>>  {
    return this.axiosInstance.get(`/api/orders/${orderId}`)
    .then(this.handleApiResponse)
    .catch((err) => this.handleApiError(err))
  }
  submitOrderForPayment(orderId: string, email: string) : Promise<ApiResponse<any>>  {
    return this.axiosInstance.post(`/api/orders/${orderId}`, {
      action: 'create-payment',
    })
    .then(this.handleApiResponse)
    .catch((err) => this.handleApiError(err))
  }
  getOrderPaymentStatusBySessionId(paymentSessionId: string) : Promise<ApiResponse<any>>  {
    return this.axiosInstance.get(`/api/orders/payment-status`, {
      params: {
        paymentSessionId: paymentSessionId
      }
    })
    .then(this.handleApiResponse)
    .catch((err) => this.handleApiError(err))
  }

  getOrders() : Promise<ApiResponse<any>>  {
    return this.axiosInstance.get(`/api/orders`)
    .then(this.handleApiResponse)
    .catch((err) => this.handleApiError(err))
  }

  getDraftOrders() : Promise<ApiResponse<any>>  {
    return this.axiosInstance.get(`/api/orders`, {
      params: {
        status: 'draft'
      }
    })
    .then(this.handleApiResponse)
    .catch((err) => this.handleApiError(err))
  }

  getOrderImageUploadUrl(orderId: string, filename: string) : Promise<ApiResponse<any>>  {
    return this.axiosInstance.get(`/api/orders/${orderId}/imageUpload`, {
      params: {
        filename: filename
      }
    })
    .then(this.handleApiResponse)
    .catch((err) => this.handleApiError(err))
  }

  //========================================
  //========================================
  // Public
  //========================================
  //========================================

  postPublicOpenOrder(orderId: string, sig: string) : Promise<ApiResponse<any>>  {
    return this.axiosInstance.post(`/api/public/open-order`, {
      orderId:orderId,
      sig:sig,
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  getPublicOrderById(orderId) : Promise<ApiResponse<any>>  {
    return this.axiosInstance.get(`/api/public/order/${orderId}`)
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  getPublicFonts(query: string, page: string) : Promise<ApiResponse<any>>  {
    return this.axiosInstance.get(`/api/public/fonts`, {
      params: {
        query: query,
        page: page ? JSON.stringify(page) : ''
      }
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  postPublicOrderMessage(orderId, name, message, email) : Promise<ApiResponse<any>>  {
    return this.axiosInstance.post(`/api/public/order/add-message`, {
      orderId: orderId,
      name: name,
      message: message,
      email: email,
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }


  postPublicOrderUpdateMessage(orderId, name, message, messageId, messageSig) : Promise<ApiResponse<any>>  {
    return this.axiosInstance.post(`/api/public/order/update-message`, {
      orderId: orderId,
      name: name,
      message: message,
      messageId: messageId,
      messageSig: messageSig,
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  postPublicVerifyEditOrderMessage(orderId, messageId, messageSig) {
    return this.axiosInstance.post(`/api/public/order/verify-edit-message`, {
      orderId: orderId,
      messageId: messageId,
      messageSig: messageSig,
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  postPublicFeedback(cardId: string, rating: string, comments: string) : Promise<ApiResponse<any>>  {
    return this.axiosInstance.get(`/api/feedback/${cardId}`)
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }


  //========================================
  //========================================
  // Admin
  //========================================
  //========================================

  adminGetPendingApprovals() : Promise<ApiResponse<{ cards: Array<Card>}>> {
    return this.axiosInstance.get(`/api/admin/approvals`, {
      params: {
        type: 'cards'
      }
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  adminGetPendingCreatorApprovals() : Promise<ApiResponse<{ cards: Array<Card>}>> {
    return this.axiosInstance.get(`/api/admin/approvals`, {
      params: {
        type: 'users'
      }
    })
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  adminPostApproveCard(cardId, decision, comments) : Promise<ApiResponse<any>> {
    return this.axiosInstance.post(`/api/admin/approve`,{
      type: 'card',
      id: cardId, decision, comments
    } )
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

  adminPostApproveCreator(userId, decision, comments) : Promise<ApiResponse<any>> {
    return this.axiosInstance.post(`/api/admin/approve`,{
      type: 'creator',
      id: userId, decision, comments
    } )
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }
  adminUpdateCardCategories(data) : Promise<ApiResponse<Array<CardGroup>>> {
    return this.axiosInstance.post(`/api/admin/update-groups`, data)
        .then(this.handleApiResponse)
        .catch((err) => this.handleApiError(err))
  }

}

//////////////////////
// Handlers

const DEFAULT_4XX_ERROR = "Oh no, a bad request was received, please try again.";
const DEFAULT_404_ERROR = "Uh-oh, there's nothing here... Please go back.";
const DEFAULT_5XX_ERROR = "Oops something went wrong on our side, please try again.";
const DEFAULT_NO_RESPONSE_ERROR = "Oops, looks like our service is down at the moment. Please try again later.";
const DEFAULT_PERMISSION_ERROR = "You do not have permission to access this.";

type DefaultResponse = {
  result: any,
  message: string,
  error: string,
  timestamp: Date,
}

function _handleResponse(response, default4XXError, default5XXError) : DefaultResponse {
  const status = response.status;
  const defaultError = default4XXError || default5XXError
  if (_is2xxError(status)) {
    return response.data;
  } else if(_isPermissionError(status)) {
    throw new Error(DEFAULT_PERMISSION_ERROR);
  } else if(_is4xxError(status)) {
    throw new Error(defaultError || DEFAULT_4XX_ERROR);
  } else if (_is5xxError(status)) {
    throw new Error(defaultError || DEFAULT_5XX_ERROR);
  } else {
    throw new Error(defaultError || DEFAULT_5XX_ERROR);
  }
}


function _handleResponseError(err: AxiosError, default4XXError, default5XXError) {
  const response = err.response;
  const defaultError = default4XXError || default5XXError

  if (response == null) {
    throw new Error(DEFAULT_NO_RESPONSE_ERROR)
  }
  const status = response.status;

  const data = response.data;

  // ServiceHandler.logger.error("Error response", response);

  if(_is404Error(status)) {
    // ServiceHandler.logger.error("404 error", status);
    throw new Error(defaultError || DEFAULT_404_ERROR)
  }

  if (!isEmpty(data) && !isEmpty(data.message)) {
    // ServiceHandler.logger.error("Error from backend with message", data.message);
    throw new Error(data.message);
  } else if (!isEmpty(data) && !isEmpty(data.error)) {
    console.error("Error from backend with message", data.error);
    throw new Error(data.error);
  }
  else if(_isPermissionError(status)) {
    console.error("Permissions error", status);
    throw new Error(DEFAULT_PERMISSION_ERROR)
  } else if(_is404Error(status)) {
    console.error("404 error", status);
    throw new Error(defaultError || DEFAULT_404_ERROR)
  } else if(_is4xxError(status)) {
    console.error("4XX error", status);
    throw new Error(defaultError || DEFAULT_4XX_ERROR)
  } else if(_is5xxError(status)) {
    console.error("5XX error", status);
    throw new Error(defaultError || DEFAULT_5XX_ERROR)
  } else {
    console.error("Unknown error", status);
    throw new Error(defaultError || DEFAULT_5XX_ERROR)
  }
}

function _is2xxError(status) {
  return status.toString().startsWith(2);
}

function _is404Error(status) {
  return status.toString().startsWith(404);
}

function _is4xxError(status) {
  return status.toString().startsWith(4);
}

function _isPermissionError(status) {
  return status === 401 || status === 402 || status === 403;
}

function _is5xxError(status) {
  return status.toString().startsWith(5);
}
