import {createSlice} from '@reduxjs/toolkit';
import ApiService from '../services/ApiService';

const apiService = new ApiService();

const initialState = {
  cardOccasionFilter: null,
  cardAgeFilter: null,
  cardRecipientFilter: null,
  cardStylesFilter: null,
  filterQueryParams: {},
};

export const viewCardsSlice = createSlice({
  name: 'counter',
  initialState: {...initialState},
  reducers: {
    store_viewCardSetCardOccasionFilter: (state, action) => {
      state.cardOccasionFilter = action.payload;
    },
    store_viewCardSetCardAgeFilter: (state, action) => {
      state.cardAgeFilter = action.payload;
    },
    store_viewCardSetCardRecipientFilter: (state, action) => {
      state.cardRecipientFilter = action.payload;
    },
    store_viewCardsClearFilter: (state, action) => {
      state.cardOccasionFilter = null
      state.cardRecipientFilter = null
      state.cardAgeFilter = null
    },
    store_viewCardsSetFilters: (state, action) => {
      state.cardRecipientFilter = action.payload?.cardRecipientFilter
      state.cardAgeFilter = action.payload?.cardAgeFilter
      state.cardStylesFilter = action.payload?.cardStylesFilter
    },
    store_viewCardsGenerateFilter: (state, action) => {
      state.filterQueryParams = {
        occasion: state.cardOccasionFilter?.name,
        recipient: state.cardRecipientFilter?.name,
        age: state.cardAgeFilter?.name,
      }
    },
  },
});

export const store_viewCards_doSetFilters = (filters) => async (dispatch, getState) => {
  const state = getState()
  const cardRecipientFilter = state.staticDataSlice?.cardGroupRecipients?.filter(f => filters?.recipients?.includes(f.name))
  const cardAgeFilter = state.staticDataSlice?.cardGroupAges?.filter(f => filters?.age?.includes(f.name))
  const cardStylesFilter = state.staticDataSlice?.cardGroupStyles?.filter(f => filters?.style?.includes(f.name))

  await dispatch(store_viewCardsSetFilters({
    cardRecipientFilter,
    cardAgeFilter,
    cardStylesFilter,
  }))
}

// Action creators are generated for each case reducer function
export const {
  store_viewCardSetCardOccasionFilter,
  store_viewCardSetCardAgeFilter,
  store_viewCardSetCardRecipientFilter,
  store_viewCardsGenerateFilter,
  store_viewCardsSetFilters,
} = viewCardsSlice.actions;

export const store_viewCards_getCardFiltersSearchParam = (state) => state.viewCardsSlice.filterQueryParams;
export const store_viewCards_getCardOccasionFilter = (state) => state.viewCardsSlice.cardOccasionFilter;
export const store_viewCards_getCardAgeFilter = (state) => state.viewCardsSlice.cardAgeFilter;
export const store_viewCards_getCardStylesFilter = (state) => state.viewCardsSlice.cardStylesFilter;
export const store_viewCards_getCardRecipientFilter = (state) => state.viewCardsSlice.cardRecipientFilter;
export default viewCardsSlice.reducer;