import React from 'react'

class PersonaliseCardUtils {
  getImageEntities(entities: any) {
    const entitiesWithAssets = entities?.filter((e) => e.state.type === 'image' || e.state?.imageData != null)
    console.debug(`Image Entities:`, entitiesWithAssets)
    return entitiesWithAssets
  }

  getImageElementFromEntityState (entityState) {
    if (entityState?.fileName && entityState?.imageData) {
      console.log(`Getting image asset, filename: ${entityState.fileName}`)
      return this.getImageElementForFile(entityState.fileName, entityState.imageData)
    }

    return null
  }
  getImageElementForFile (filename, data) {
    return <img id={`${filename}`} src={data} timeout="10000"/>
  }
}

export default new PersonaliseCardUtils()